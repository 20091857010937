import { GetStaticPaths, GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { ReactElement, useEffect } from 'react';
import { Storyblok, useStoryblok } from 'storyblok';

import { Layout } from '@/components';
import FontLoader from '@/components/global/FontLoader';
import Footer from '@/components/global/Footer';
import Header from '@/components/global/Header';
import NavbarFloating from '@/components/global/NavbarFloating';
import PageTheme from '@/components/global/PageTheme';
import { TypefaceMenuProvider } from '@/components/providers/TypefaceMenuProvider';
import { BaseSeo } from '@/components/seo/BaseSeo';
import { serverApolloClient } from '@/lib/auth/useApolloClient';
import { AWSClient } from '@/lib/aws/client';
import { VERSION } from '@/lib/constants';
import checkoutDataLayer from '@/lib/datalayer/checkout';
import fontLicences from '@/lib/fonts/fontLicences';
import { contextToRegionQuery } from '@/lib/regions';
import sleep from '@/lib/storyblok/sleep';
import { getStoryBlokProps } from '@/lib/storyblok/storyblokGetStaticProps';
import { getStoryBlokNavLinks } from '@/lib/storyblok/storyblokNavLinks';
import { getTypefacePages } from '@/lib/storyblok/storyblokSiblingPageNav';
import { updateFontsToWebfont } from '@/lib/util';
import {
  TypefaceProductPageQuery,
  TypefaceProductPageQueryDocument,
  TypefaceProductPageQueryVariables
} from '@/saleor/api';

import DynamicComponent from '../../storyblok/components/DynamicComponent';

export type OptionalQuery = {
  configuredFont?: string;
  variant?: string;
};

const TypefacePage = ({
  fonts,
  product,
  story,
  preview,
  resolve_relations,
  productLicences,
  childPages
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const sbStory = preview ? useStoryblok(story, preview, resolve_relations) : story;
  const {
    content: { Fonts = [], headerTheme = '', SEO = {}, pageTheme = [] },
    name = '',
    full_slug
  } = sbStory;

  const globalLinks = getStoryBlokNavLinks(story);

  // add 'view_item' to the dataLayer
  useEffect(() => {
    checkoutDataLayer.viewItem(product);
  }, [product]);

  return (
    <>
      {pageTheme && <PageTheme themeBlok={pageTheme} />}
      <BaseSeo
        title={SEO?.title}
        description={SEO?.description || product?.seoDescription}
        ogDescription={SEO?.og_description || product?.seoDescription}
        ogTitle={SEO?.og_title || product?.seoTitle}
        image={SEO?.og_image || product?.thumbnail?.url}
        path={full_slug}
      />
      <TypefaceMenuProvider items={childPages}>
        <Header
          blok={sbStory.content}
          navigation={globalLinks}
          headerTheme={headerTheme}
          pageName={name}
          onTypefacePage
        />
      </TypefaceMenuProvider>
      {/* Load the variable fonts only once in the page */}
      {fonts?.length > 0 &&
        fonts.map((variableFont) => {
          const fontToLoad = updateFontsToWebfont({ font: variableFont.meta.path });

          return (
            <FontLoader
              key={variableFont.name}
              s3Path={fontToLoad}
              fontName={variableFont.name}
              fullPath={!fontToLoad.includes('https://')}
            />
          );
        })}
      {Fonts.map((bl) => (
        <DynamicComponent key={bl._uid} blok={bl} />
      ))}
      <NavbarFloating links={childPages} product={product} productLicences={productLicences} />
      <main id="main">
        {sbStory && sbStory.content && <DynamicComponent blok={sbStory.content} />}
      </main>

      <Footer theme="grey-light" navigation={globalLinks.footerLinks} />
    </>
  );
};

export default TypefacePage;

export const getStaticPaths: GetStaticPaths = async () => {
  const { data } = await Storyblok.get('cdn/links/', {
    starts_with: 'typefaces/'
  });

  // get top level page slugs to build
  const paths = Object.keys(data.links).reduce((acc, link) => {
    const page = data.links[link];

    if (!page) return acc;

    const { is_startpage: isStartpage, slug } = page;
    const isFolderHome = slug === 'typefaces/';

    if (isFolderHome || !isStartpage) return acc;

    return [...acc, slug];
  }, []);

  // get first 9 pages
  // mathcing those that are not lazy loaded on index page
  const sortedPaths = paths.sort();
  const firstPaths = sortedPaths.slice(0, 9);
  const formattedSortedPaths = firstPaths.map((path) => ({
    params: { slug: path }
  }));

  return {
    paths: formattedSortedPaths,
    fallback: 'blocking'
  };
};

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const productSlug = context.params?.slug?.toString()!;

  const preview = context.preview || false;
  const resolveRelations = 'page.globalHeader,page.globalFooter';

  const storyPromise = getStoryBlokProps({
    pageSlug: `typefaces/${productSlug}`,
    context,
    VERSION,
    resolveRelations
  });

  const childPagesPromise = getTypefacePages(preview, `typefaces/${productSlug}/`, false);

  // Saleor data
  const productDataPromise = serverApolloClient.query<
    TypefaceProductPageQuery,
    TypefaceProductPageQueryVariables
  >({
    query: TypefaceProductPageQueryDocument,
    variables: {
      slug: productSlug,
      ...contextToRegionQuery(context)
    }
  });

  const pageData = await Promise.all([storyPromise, childPagesPromise, productDataPromise]).then(
    (values) => values
  );

  await sleep();

  const story = pageData[0],
    childPages = pageData[1],
    response = pageData[2];

  if (story?.notFound) {
    return story;
  }

  // AWS S3 wakamai fondue font data
  const { VariableFonts = [] } = story.content;

  const awsS3fontDataPromises = VariableFonts.map(async (font) => {
    const params = {
      properties: 'slug,isVariable,variable',
      version: font.Version
    };

    const awsS3FontData = await AWSClient.fontData('fondue', { params, pathAppend: font.Path });

    return { name: font.Name, ...awsS3FontData };
  });

  const awsS3fontData = await Promise.all(awsS3fontDataPromises);

  const awsS3fontDataFormatted = awsS3fontData.map((awsS3font) => {
    const { meta, name, slug, isVariable, variable } = awsS3font;

    return {
      name,
      slug: slug || null,
      meta: meta || null,
      isVariable: isVariable || null,
      variable: variable || null
    };
  });

  const loopThroughJSON = (thing, checker, match) => {
    if (!thing) return;
    if (Array.isArray(thing)) {
      thing.forEach((arr) => loopThroughJSON(arr, checker, match));
      return;
    }
    if (typeof thing === 'object') {
      if (checker(thing)) {
        match(thing);
      } else {
        Object.values(thing).forEach((ob) => loopThroughJSON(ob, checker, match));
      }
    }
  };

  // loop through SB JSon, and attach fontData to variable-font SB component
  loopThroughJSON(
    story.content.body,
    (obj) => obj?.component === 'VariableFont',
    (match) => {
      awsS3fontDataFormatted.forEach((VFdata) => {
        if (VFdata.name === match.fontStyle) {
          match.data = VFdata;
        }
      });
    }
  );

  const isTypeface = response?.data?.product?.productType?.slug === 'font-family';

  if (!response.data.product?.id || !isTypeface) {
    return {
      notFound: true
    };
  }

  return {
    props: {
      fonts: Object.values(awsS3fontDataFormatted),
      product: response.data.product,
      isTypeface,
      childPages,
      productLicences: fontLicences,
      preview,
      story: story || null,
      resolve_relations: resolveRelations
    },
    revalidate: 60 * 60 // value in seconds, how often ISR will trigger on the server
  };
};

TypefacePage.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};
